import { Contractor } from './../contractor.model';
import { Customer } from './../customer.model';
import { Serializable } from '../deserialize.service';

export class TriageRequest implements Serializable<TriageRequest> {
    public customer: Customer;
    public contractor: Contractor;

    deserialize(input: any) {
        if (input.customer) {
            this.customer = new Customer().deserialize(input.customer);
        }
        if (input.contractor) {
            this.contractor = new Contractor().deserialize(input.contractor);
        }
        return this;
    }
}
