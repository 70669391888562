import { Serializable } from '../deserialize.service';

export class Authorization implements Serializable<Authorization> {
    id: string;
    name: string;

    deserialize(input) {
        this.id = input.id;
        this.name = input.name;
        return this;
    }
}
